import React, {useContext, useState} from 'react';
import classes from "./Menu.module.css";
import {Context} from "../../index";
import {observer} from 'mobx-react-lite';
import exit from '../../img/exit.svg';
import exitHover from '../../img/exithover.svg';
import settings from '../../img/settings.svg';
import settingsHover from '../../img/settingshover.svg';
import addWater from '../../img/addwater.svg';
import addWaterHover from '../../img/addwaterhover.svg';
import MenuButton from "../MenuButton/MenuButton";

const Menu = (props) => {
    const {nodes} = props;
    const {store} = useContext(Context);
    const [leftState, setLeftState] = useState(-117);

    const waterNodeClick = (element) => {
        store.setCurrentFrame(element);
        store.setFormAdminProp(false);
        store.setFormCurrentNode(true);
    }

    const adminPanelClick = () => {
        store.setFormAdminProp(true);
        store.setFormCurrentNode(false);
    }

    return (
        <div
            className={classes.menu}
            style={{left: leftState}}
            onMouseOver={() => setLeftState(0)}
            onMouseLeave={() => setLeftState(-117)}
        >
            <div className={classes.wrapper}>
                <div className={classes.btn}>
                    <MenuButton
                        background={true}
                        text="Выход"
                        textLittle={true}
                        src={exit}
                        srcHover={exitHover}
                        onClick={() => {
                            store.logout()
                        }}
                        alt="Выход"
                        title="Выход из аккаунта"
                    />
                </div>

                {store.isAdmin ? <div className={classes.btn}>
                    <MenuButton
                        background={true}
                        text="Настройки"
                        textLittle={true}
                        src={settings}
                        srcHover={settingsHover}
                        onClick={() => adminPanelClick()}
                        alt="Настройки"
                        title="Настройки администратора"
                    />
                </div> : null}
                {nodes.map(element => <div key={element.id} className={classes.btn}>
                    <MenuButton
                        background={true}
                        text={element.nameNode.slice(0, 10)}
                        textLittle={true}
                        src={addWater}
                        srcHover={addWaterHover}
                        onClick={() => waterNodeClick(element)}
                        alt={element.nameNode}
                        title={element.nameNode}
                    />
                </div>)}
            </div>
        </div>
    );
};

export default observer(Menu);


