import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Context} from "../../index";
import Menu from "../Menu/Menu";
import classes from "./MainComponent.module.css";
import FormAdmin from "../FormAdmin/FormAdmin";
import MenuButton from "../MenuButton/MenuButton";
import close from "../../img/close.svg";
import closeHover from "../../img/closehover.svg";

const MainComponent = () => {
    const {store} = useContext(Context);
    const [availableNodes, setAvailableNodes] = useState([]);

    useEffect(() => {
        const allowNodesArray = store.user.allowFrames.split('/');
        store.getNodes()
            .then(response =>{
                const arrayOfNodes = response.data.filter((element) => {
                    for (let item of allowNodesArray)
                        if (Number(item) === element.id) return true;
                    return false;
                });
                setAvailableNodes(arrayOfNodes);
            })
        }, [store.isFormAdminProp]);

    return (
        <div className={classes.wrapper}>
            {/*Отображение меню в котором можно выбрать настройки, выйти из профиля или выбрать узел*/}
            <Menu nodes={availableNodes}/>

            {//Вывод формы выбранного узла
                store.isFormCurrentNode
                    ? <div className={`${classes.form} ${classes.formWH}`}>
                        <div className={classes.headerForm}>
                            <div className={classes.titleForm}>Узел водоподготовки {store.currentFrame.nameNode}</div>
                            <MenuButton
                                src={close}
                                srcHover={closeHover}
                                onClick={() => store.setFormCurrentNode(false)}
                                alt="Закрыть окно"
                                title="Закрыть окно"
                            />
                        </div>
                        {/*{store.currentFrame.ipAddress}*/}
                        <iframe
                            className={classes.frameWaterClean}
                            src={store.currentFrame.ipAddress}
                            referrerPolicy="strict-origin-when-cross-origin"
                            frameBorder="0">Узел не
                            доступен
                        </iframe>
                    </div> : null}
            {//Вывод формы НАСТРОЙКИ АДМИНИСТРАТОРА
                store.isFormAdminProp
                    ? <div className={classes.form}>
                        <div className={classes.headerForm}>
                            <div className={classes.titleForm}>Настройки администратора</div>
                            <MenuButton
                                src={close}
                                srcHover={closeHover}
                                onClick={() => store.setFormAdminProp(false)}
                                alt="Закрыть окно"
                                title="Закрыть окно"
                            />
                        </div>
                        <div className={classes.formAdmin}>
                            <FormAdmin/>
                        </div>
                    </div> : null
            }

        </div>
    );
};

export default observer(MainComponent);
